<template>
  <span
    ><span v-if="days > 0">{{ days }} Day<span v-if="days > 1">s</span></span> <span v-if="hours > 0">{{ hours }} Hr<span v-if="hours > 1">s</span></span> <span v-if="minutes > 0 && days < 1">{{ minutes }} Min<span v-if="minutes > 1">s</span></span
    ><span v-if="days == 0 && hours == 0 && minutes == 0 && seconds <= 60">0 Min</span></span
  >
</template>

<script>
export default {
  name: "TimeSince",
  data() {
    return {
      interval: null,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      intervals: {
        second: 1000,
        minute: 1000 * 60,
        hour: 1000 * 60 * 60,
        day: 1000 * 60 * 60 * 24
      }
    };
  },
  props: {
    startdate: {
      required: true
    },
    enddate: String,
    status: String
  },
  mounted() {
    this.interval = setInterval(() => {
      this.updateDiffs();
    }, 500);
    this.updateDiffs();
  },
  destroyed() {
    clearInterval(this.interval);
  },
  methods: {
    updateDiffs() {
      var startDateObject = new Date(this.startdate);
      var endDateObject;

      if ((this.status == "canceled" || this.status == "completed") && this.enddate) {
        endDateObject = new Date(this.enddate);
      } else {
        endDateObject = new Date();
      }

      let diff = Math.abs(endDateObject.getTime() - startDateObject.getTime());
      this.days = Math.floor(diff / this.intervals.day);
      diff -= this.days * this.intervals.day;
      this.hours = Math.floor(diff / this.intervals.hour);
      diff -= this.hours * this.intervals.hour;
      this.minutes = Math.floor(diff / this.intervals.minute);
      diff -= this.minutes * this.intervals.minute;
      this.seconds = Math.floor(diff / this.intervals.second);
    },
    doubleDigit(n) {
      return n > 9 ? "" + n : "0" + n;
    }
  }
};
</script>
