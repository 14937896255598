<template>
  <ion-list mode="ios">
    <ion-item lines="none" v-if="isSecureChat && $can(I.SEND_MESSAGE)" class="cursor-pointer" @click="resendSCLink">
      <ion-icon class="icon-small icon-dark-grey margin-right-five" name="link" color="medium" @click="resendSCLink"></ion-icon>
      <ion-label color="dark" mode="ios">
        Resend Secure Chat Link
      </ion-label>
    </ion-item>
    <ion-item @click="copyTranscript()" lines="none" class="cursor-pointer">
      <IconCopy slot="start" class="icon-small icon-dark-grey margin-right-five" />
      <ion-label color="dark" mode="ios">
        Copy Transcript
      </ion-label>
    </ion-item>
    <ion-item v-if="$can(I.SEND_TRANSCRIPT_TO_EHR)" @click="sendTranscript()" lines="none" class="cursor-pointer">
      <IconSend slot="start" class="icon-small icon-dark-grey margin-right-five" />
      <ion-label color="dark" mode="ios">Send Transcript to EHR</ion-label>
    </ion-item>
    <ion-item v-if="$can(I.MODIFY_PATIENT_DATA)" @click="showEditSubjectPopover()" lines="none" class="cursor-pointer">
      <IconEditPencil class="icon-small icon-dark-grey margin-right-five" />
      <ion-label color="dark" mode="ios">
        Edit Conversation Title
      </ion-label>
    </ion-item>
    <ion-item v-if="$can(I.SEND_MESSAGE) && this.status != 'closed'" @click="showEndConvoPopover()" lines="none" class="cursor-pointer">
      <ion-icon class="icon-small icon-dark-grey margin-right-five" name="md-close-circle-outline"></ion-icon>
      <ion-label color="dark" mode="ios">
        End Conversation
      </ion-label>
    </ion-item>
    <MessagesCopyToClipboard :messages="filteredMessages" />
  </ion-list>
</template>

<script>
import { send as httpSend } from "@/services/Api";
import MessageEndconvoOptions from "./MessageEndconvoOptions";
import MessageEditConvoSubject from "./MessageEditConvoSubject";
import MessagesCopyToClipboard from "./MessagesCopyToClipboard";
import IconEditPencil from "@/components/Global/Icons/IconEditPencil";
import IconCopy from "@/components/Global/Icons/IconCopy";
import IconSend from "@/components/Global/Icons/IconSend";

import { addIcons } from "ionicons";
import { link, closeCircleOutline } from "ionicons/icons";
import { EventBus } from "@/services/Events";

addIcons({
  "md-link": link.md,
  "md-close-circle-outline": closeCircleOutline.md
});

export default {
  name: "PopoverConversationOptions",
  props: {},
  components: {
    IconEditPencil,
    IconSend,
    IconCopy,
    MessagesCopyToClipboard
  },
  computed: {
    isSecureChat() {
      return this.msgChannel === "zeus";
    }
  },
  methods: {
    showEndConvoPopover: function() {
      this.$ionic.popoverController
        .create({
          component: MessageEndconvoOptions,
          // uncomment for popover snap to element
          // event: ev,
          cssClass: "popover-width-lg",
          mode: "ios",
          componentProps: {
            data: {
              messageTitle: this.messageTitle,
              interactionId: this.interactionId,
              initiator: this.initiator,
              status: this.status,
              beneficiaryId: this.beneficiaryId
            }
          }
        })
        .then(m => m.present());
    },
    copyTranscript() {
      EventBus.$emit("copyMessagesToClipboard");
      this.$ionic.popoverController.dismiss();
    },
    sendTranscript() {
      this.$ionic.popoverController.dismiss();

      const payload = {
        account_id: this.accountId,
        payload: {
          beneficiary_id: this.beneficiaryId,
          interaction_id: this.interactionId
        },
        subject: this.messageTitle
      };

      const method = "post";
      const path = document.config.transcriptToEHR + "send_transcript_to_ehr";

      httpSend({ path, method, authToken: true, payload })
        .then(() => {
          this.$ionic.toastController
            .create({
              header: "Success!",
              message: "The transcript has been sent.",
              duration: 4000,
              color: "primary",
              mode: "ios"
            })
            .then(m => m.present());
        })
        .catch(error => {
          this.$ionic.toastController
            .create({
              header: "Could not send transcript. Please try again later.",
              message: error,
              duration: 10000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    showEditSubjectPopover: function() {
      this.$ionic.popoverController
        .create({
          component: MessageEditConvoSubject,
          // uncomment for popover snap to element
          // event: ev,
          cssClass: "popover-width-lg",
          mode: "ios",
          componentProps: {
            data: {
              messageTitle: this.messageTitle,
              interactionId: this.interactionId,
              initiator: this.initiator,
              status: this.status,
              beneficiaryId: this.beneficiaryId,
              urgency: this.urgency,
              programId: this.programId
            }
          }
        })
        .then(m => m.present());
    },
    resendSCLink: async function() {
      const method = "post";
      const path = document.config.resendZeusLink + this.interactionId + "/send";
      const payload = this.isDbugga && this.dbuggaMode ? "dbugga" : "twilio";
      const headers = {
        "Content-Type": "text/plain"
      };

      try {
        await httpSend({ path, method, payload, authToken: true, headers });

        this.$ionic.toastController
          .create({
            header: "Success!",
            message: "A new link to Secure Chat has been sent",
            duration: 4000,
            color: "primary",
            mode: "ios"
          })
          .then(m => m.present());
      } catch (error) {
        this.$ionic.toastController
          .create({
            header: "Error!",
            message: "There was a problem resending the secure chat link. Please try again later.",
            duration: 4000,
            position: "top"
          })
          .then(m => m.present());

        khanSolo.log(error);
      }
    }
  }
};
</script>

<style scoped>
ion-list.list-ios {
  margin-bottom: 0;
}
ion-item {
  padding: 0 5px;
}
ion-item:hover {
  background: var(--ion-color-light);
}
ion-label {
  font-weight: 500;
}
#__all-text {
  position: fixed;
  width: 1px;
  height: 1px;
  left: -9999px;
}
</style>
