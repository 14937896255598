<template>
  <div class="prime-modal">
    <ion-header>
      <ion-toolbar>
        <IconVideo slot="start" class="icon-small" />
        <h5 slot="start">Initiate Video Chat</h5>
        <ion-button
          slot="end"
          mode="ios"
          @click="closeModal()"
          expand="full"
          color="medium"
          fill="clear"
        >
          <ion-icon name="md-close"></ion-icon>
        </ion-button>
      </ion-toolbar>
    </ion-header>
    <ion-content class="prime-education-modal-content">
      <img src="../../assets/doc-video-chat.svg" alt="Chat Video" class="central-img" />
      <p>This will initiate a video visit by sending a link for the patient to join via their device, and forwarding you to the video visit interface.</p>
      <p>You can also rejoin the visit session by clicking the link that was sent to the patient.</p>
    </ion-content>
    <ion-footer class="margin-bottom-twenty">
      <ion-grid>
        <ion-row class="pad-lr-twenty full-height">
          <ion-col class="pad-right-twenty">
            <button
              @click="closeModal(null)"
              class="prime-button button-block button-secondary margin-top-ten margin-bottom-ten"
            >Cancel</button>
          </ion-col>
          <ion-col class="full-height overflow-y-scroll">
            <button
              @click="sendLink"
              class="prime-button button-block button-primary margin-top-ten margin-bottom-ten"
            >Send Link and Start Visit</button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-footer>
  </div>
</template>

<script>
import IconVideo from "@/components/Global/Icons/IconVideo";
import { send as httpSend } from "@/services/Api";
import store from "@/store";
export default {
  name: "ModalVideoConferencing",
  components: {
    IconVideo
  },
  data() {
    return {
      beneficiaryId: null,
      beneficiaryAccountId: null
    };
  },
  mounted() {
    this.beneficiaryId = store.state.chatProfessional.currentPatient.beneficiary.id;
    this.beneficiaryAccountId = store.state.chatProfessional.currentPatient.beneficiary.accountId;
  },
  methods: {
    closeModal() {
      this.$ionic.modalController.dismiss({ description: null });
    },
    reformatPhoneNumber(phoneNumberString) {
      var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
      var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return [match[2], "-", match[3], "-", match[4]].join("");
      }
      return null;
    },
    async getPatientPhoneNumber() {
      const method = "get";
      const path = document.config.accountChannel + this.beneficiaryAccountId + "/accountchannel";
      try {
        let { data } = await httpSend({ path, method, authToken: true });
        let pNumber = data.find(contact => contact.channel === "sms").identifier || "";
        return this.reformatPhoneNumber(pNumber);
      } catch {
        return "";
      }
    },
    async sendLink() {
      let toPhoneNumber = await this.getPatientPhoneNumber();

      const method = "post";
      const payload = {
        beneficiaryId: this.beneficiaryId,
        toPhoneNumber
      };

      const path = document.config.virtualVisit;

      try {
        let { data } = await httpSend({ path, payload, method, authToken: true });
        
        this.closeModal();
        const name = store.getters["currentProfessional/displayName"];
        const link = `${data.videoChatLink}?name=${name}`;

        window.open(link);
      } catch (error) {
        khanSolo.log(error);
      }
    }
  }
};
</script>
<style scoped>
.central-img {
  width: 80%;
  display: block;
  margin: 2rem auto;
}
</style>
