<template>
  <div class="prime-modal">
    <ion-header class="prime-view-past-conversation-header">
      <ion-toolbar>
        <IconChatBubble slot="start" class="icon-small" />
        <h5 slot="start">{{ title }}</h5>
        <ion-button slot="end" mode="ios" @click="closeModal(null)" expand="full" color="primary" fill="clear"><ion-icon name="md-close"></ion-icon></ion-button>
      </ion-toolbar>
    </ion-header>
    <ion-content class="prime-view-past-conversation-container">
      <div v-if="isLoadingMessages" class="text-center pad-ten full-height">
        <div class="centerItems text-muted display-flex valign full-height full-width">
          <ion-spinner name="lines" class="spinner-large valign"></ion-spinner>
        </div>
      </div>
      <div v-else id="chatTranscript" class="card-scroll-vert pad-top-ten">
        <ChatBubble v-for="message in chatTranscript" :key="message.id" v-bind="{ message }" />
      </div>
    </ion-content>
    <ion-footer>
      <ion-row>
        <ion-col class="pad-five">
          <button @click="copyChatTranscript" class="prime-button button-secondary button-block margin-top-ten">
            <IconCopy slot="start" class="icon-small" />
            Copy Outreach Transcript
          </button>
        </ion-col>
        <ion-col class="pad-five" v-if="$can(I.SEND_TRANSCRIPT_TO_EHR)">
          <button @click="sendTranscript()" class="prime-button button-secondary button-block margin-top-ten">
            <IconSend slot="start" class="icon-small" />
            Send Transcript to EHR
          </button>
        </ion-col>
        <ion-col class="pad-five" v-else>
          <button class="prime-button button-secondary button-block disabled margin-top-ten" v-tooltip.top-start="{ content: $orgMissingPermissions('The Sending Transcript to EHR feature') }">
            <IconSend slot="start" class="icon-small" />
            Send Transcript to EHR
          </button>
        </ion-col>
      </ion-row>
    </ion-footer>
  </div>
</template>

<script>
import IconChatBubble from "@/components/Global/Icons/IconChatBubble";
import { send as httpSend } from "@/services/Api";
import ChatBubble from "@/components/Chat/ChatBubble";
import IconCopy from "@/components/Global/Icons/IconCopy";
import IconSend from "@/components/Global/Icons/IconSend";
import PopoverCopyToClipboard from "@/components/TaskDetails/PopoverCopyToClipboard";

export default {
  name: "ModalChatTranscript",
  props: {
    title: { type: String, default: "View Conversation" },
    interactionId: Number,
    comments: Array
  },
  components: {
    IconChatBubble,
    ChatBubble,
    IconCopy,
    IconSend
  },
  data() {
    return {
      chatTranscript: undefined,
      isLoadingMessages: false
    };
  },
  created() {
    this.getChatTranscript(this.interactionId);
  },
  methods: {
    closeModal() {
      this.$ionic.modalController.dismiss();
    },
    getChatTranscript(id) {
      this.isLoadingMessages = true;
      const method = "get";
      const path = document.config.messageApi + id + "/messages";

      httpSend({ path, method, authToken: true })
        .then(result => {
          this.chatTranscript = result.data.messages;
        })
        .catch(error => {
          this.catchError(error, "Could not get chat transcript. Please try again later.");
        })
        .finally(() => (this.isLoadingMessages = false));
    },
    copyChatTranscript(evt) {
      this.$ionic.popoverController
        .create({
          component: PopoverCopyToClipboard,
          event: evt,
          mode: "ios",
          cssClass: "popover-width-auto",
          componentProps: {
            propsData: {
              messages: this.chatTranscript,
              comments: this.comments
            }
          }
        })
        .then(p => p.present());
    },
    sendTranscript() {
      const payload = {
        account_id: this.accountId,
        payload: {
          beneficiary_id: this.beneficiaryId,
          interaction_id: this.interactionId
        },
        subject: this.messageTitle
      };

      const method = "post";
      const path = document.config.transcriptToEHR + "send_transcript_to_ehr";

      httpSend({ path, method, authToken: true, payload })
        .then(() => {
          this.$ionic.toastController
            .create({
              header: "Success!",
              message: "The transcript has been sent.",
              duration: 4000,
              color: "primary",
              mode: "ios"
            })
            .then(m => m.present());
        })
        .catch(error => {
          this.$ionic.toastController
            .create({
              header: "Could not send transcript. Please try again later.",
              message: error,
              duration: 10000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>

<style>
.view-conversation-message-panel .active-conversation-header {
  margin-top: 15px !important;
  margin-bottom: 10px !important;
}
.view-conversation-message-panel .active-conversation-title-container {
  display: none;
}
.prime-modal ion-content.prime-view-past-conversation-container ion-footer {
  display: none;
}
.prime-modal ion-header.prime-view-past-conversation-header {
  border-bottom: 2px solid var(--ion-color-light-shade);
}
.prime-modal ion-content.prime-view-past-conversation-container {
  --background: var(--ion-modal-secondary-background);
}
.prime-modal ion-content.prime-view-past-conversation-container ion-content,
.prime-modal ion-content.prime-view-past-conversation-container ion-toolbar {
  --background: var(--ion-modal-secondary-background);
}
</style>
