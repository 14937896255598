import { formatDisplayName } from "@/utils/formatter";
import moment from "moment";
import { can, I } from "@/utils/permissions";

const getChannel = msg => {
  const { channel, provider } = msg;

  if (channel == "sms") {
    if (can(I.ACCESS_DEBUGGER) && provider === "dbugga") {
      return "Dbugga Text Message";
    } else {
      return "Text Message";
    }
  } else if (channel == "zeus") {
    return "Secure Chat";
  }
  return channel;
};

const getDisplayName = msg => {
  if (msg.last_name && msg.last_name.toLocaleUpperCase() === "SYSTEM") {
    return "LumerisEngage";
  }
  if (msg.source === "system") {
    return "LumerisEngage Automated System";
  }
  return formatDisplayName(msg.title, msg.first_name, msg.last_name, msg.suffix);
};

const getTimestamp = msg => moment(msg.created_at).format("M/D/YYYY - h:mmA");

const wrap = msg => {
  return {
    ...msg,
    getChannel: () => getChannel(msg),
    getDisplayName: () => getDisplayName(msg),
    getTimestamp: () => getTimestamp(msg)
  };
};

export default {
  getChannel,
  getDisplayName,
  getTimestamp,
  wrap
};
