import { send } from "@/services/Api";
import copy from "@/assets/languagePack/english";
import store from "@/store";

export default async payload => {
  // const method = "post";
  const method = "post";
  const path = document.config.tasksAPI + "/list";
  // const path = document.config.tasksAPItemp;

  try {
    const results = await send({ path, method, payload, authToken: true });
    return results.data;
  } catch (error) {
    const errorDetails = {
      header: copy.api.errors.getTasks,
      message: error
    };
    store.commit("errorHandler/toastableError", errorDetails);
  }
};
