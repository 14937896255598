<template>
  <div class="prime-modal full-height">
    <div class="full-height" v-if="isLoading">
      <div class="centerItems text-muted display-flex valign full-height full-width">
        <ion-spinner name="lines" class="spinner-large valign"></ion-spinner>
      </div>
    </div>
    <div class="full-height" v-else-if="invalidTask">
      <div class="text-muted center-center column">
        <h2 class="text-muted text-bold">Invalid Task ID</h2>

        <button @click="closeModal(null)" class="margin-top-twenty prime-button button-primary pad-lr-thirty button-fixed">
          Close
        </button>
      </div>
    </div>
    <div class="full-height column-stretch-wrap" v-else>
      <div class="column-stretch-header bottom-divider">
        <ion-row class="pad-top-ten pad-bottom-ten pad-lr-twenty ion-align-items-center desktop">
          <ion-col v-if="($can(I.MODIFY_TASK) && task && task.status && task.status != 'completed') || (task && task.status && task.status == 'canceled')" size="auto">
            <button @click="updateStatus(task.id, 'completed')" class="prime-button button-secondary button-block" :class="{ 'button-pending': isMarkingCompleted }">
              <ion-spinner name="dots" duration="1200" color="primary"></ion-spinner>
              <span>
                <IconCheckCircle class="icon-small" color="success" />
                Mark as Complete
              </span>
            </button>
          </ion-col>
          <ion-col v-else-if="(!$can(I.MODIFY_TASK) && task.status != 'completed') || task.status == 'canceled'" size="auto">
            <button class="prime-button disabled button-secondary button-block" v-tooltip.top-start="{ content: $orgMissingPermissions('The Modifying Tasks feature') }">
              <ion-spinner name="dots" duration="1200" color="primary"></ion-spinner>
              <span>
                <IconCheckCircle class="icon-small" color="success" />
                Mark as Complete
              </span>
            </button>
          </ion-col>
          <ion-col v-if="$can(I.MODIFY_TASK) && task && task.status && task.status != 'in_progress'" size="auto">
            <button @click="updateStatus(task.id, 'in_progress')" class="prime-button button-secondary button-block margin-left-ten" :class="{ 'button-pending': isMarkingInProgress }">
              <ion-spinner name="dots" duration="1200" color="primary"></ion-spinner>
              <span>Mark In Progress</span>
            </button>
          </ion-col>
          <ion-col v-else-if="!$can(I.MODIFY_TASK) && task.status != 'in_progress'" size="auto">
            <button class="prime-button disabled button-secondary button-block margin-left-ten" v-tooltip.top-start="{ content: $orgMissingPermissions('The Modifying Tasks feature') }">
              <ion-spinner name="dots" duration="1200" color="primary"></ion-spinner>
              <span>Mark In Progress</span>
            </button>
          </ion-col>
          <ion-col v-if="$can(I.MODIFY_TASK) && task && task.status && task.status != 'canceled'" class="margin-left-twenty">
            <span @click="confirmCancel(task.id, 'canceled')" class="cursor-pointer">
              <IconDeleteTrash class="icon-grey icon-small" />
            </span>
          </ion-col>
          <ion-col v-else-if="!$can(I.MODIFY_TASK) && task.status != 'canceled'" class="margin-left-twenty">
            <span class="disabled" v-tooltip.top-start="{ content: $orgMissingPermissions('The Modifying Tasks feature') }">
              <IconDeleteTrash class="icon-grey icon-small" />
            </span>
          </ion-col>
          <ion-col></ion-col>
          <ion-col size="auto">
            <ion-button mode="ios" @click="closeModal(null)" expand="full" color="primary" fill="clear"><ion-icon name="md-close"></ion-icon></ion-button>
          </ion-col>
        </ion-row>
        <ion-row class="pad-top-ten pad-bottom-ten pad-lr-twenty ion-align-items-center mobile">
          <ion-col class="mobile-1"><h3>Task Details</h3></ion-col>
          <ion-col class="mobile-2"><h3>Task Comments</h3></ion-col>
          <ion-col size="auto">
            <ion-button mode="ios" @click="closeModal(null)" expand="full" color="primary" fill="clear"><ion-icon name="md-close"></ion-icon></ion-button>
          </ion-col>
        </ion-row>
      </div>
      <div class="column-stretch-body bottom-divider">
        <ion-row class="pad-lr-twenty full-height">
          <ion-col class="pad-right-twenty right-divider full-height overflow-y-scroll mobile-1 mob-overwrite">
            <ion-row class="bottom-divider margin-top-ten">
              <ion-col>
                <div class="text-small text-muted">Task</div>
                <h5 class="text-bold margin-top-ten">{{ task.name }}</h5>
              </ion-col>
            </ion-row>
            <ion-row class="mobile mobile-1 bottom-divider pad-top-ten pad-bottom-ten">
              <ion-col size="auto">
                <div class="avatar current-patient-avatar margin-right-ten" v-if="task && task.assignedTo && task.assignedTo.firstName && task.assignedTo.firstName">
                  {{ task.assignedTo.firstName.charAt(0) + task.assignedTo.lastName.charAt(0) }}
                </div>
              </ion-col>
              <ion-col size="auto">
                <div class="text-small text-muted">Assigned To</div>
                <div class="text-bold cursor-pointer" @click="showAssignTaskPopover($event, task.id, availableProfessionals)">{{ makeAssignedName(task.assignedTo) }}</div>
              </ion-col>
              <ion-col></ion-col>
              <ion-col>
                <span class="text-center text-bold pill" :class="{ 'yellow-pill': showYellowTimeElapsedPill(task) }">
                  <TimeSince :startdate="task.createdAt" :enddate="task.lastUpdatedAt" :status="task.status"></TimeSince>
                </span>
              </ion-col>
            </ion-row>
            <ion-row class="mobile mobile-1 bottom-divider pad-top-ten pad-bottom-ten">
              <ion-col size="6">
                <div class="text-small text-muted">Status</div>
                <ion-select interface="popover" :value="task.status" class="margin-right-ten" @ionChange="updateStatusPerTask">
                  <ion-select-option v-for="(value, key, index) of taskStatusesConfig" :key="index" :value="key" class="text-bold">{{ taskStatusesConfig[key] }} </ion-select-option>
                </ion-select>
              </ion-col>
              <ion-col size="5">
                <div class="text-small text-muted">Priority</div>
                <div class="text-bold cursor-pointer text-capitalize margin-top-ten">{{ task.priority }}</div>
              </ion-col>
            </ion-row>
            <ion-row v-if="task && task.program && task.program.name != 'Text Triage'" class="bottom-divider pad-top-ten pad-bottom-ten" :if="task.program">
              <ion-col>
                <div class="text-small text-muted">For Program</div>
                <div class="text-bold margin-top-ten">{{ task.program.name ? task.program.name : "" }}</div>
              </ion-col>
            </ion-row>
            <ion-row class="bottom-divider pad-top-ten pad-bottom-ten ion-align-items-center" :if="task.beneficiary">
              <ion-col size="auto">
                <div class="avatar current-patient-avatar margin-right-ten" v-if="task && task.beneficiary && task.beneficiary.firstName && task.beneficiary.lastName">
                  {{ task.beneficiary.firstName.charAt(0) + task.beneficiary.lastName.charAt(0) }}
                </div>
              </ion-col>
              <ion-col size="auto">
                <div class="text-small text-muted">For Patient</div>
                <div class="text-bold">
                  <span>{{ (task.beneficiary.lastName ? task.beneficiary.lastName + ", " : "") + (task.beneficiary.firstName ? task.beneficiary.firstName : "") }}</span>
                  <span v-if="$can(I.VIEW_PATIENT)" @click="showPopover($event, task)" class="margin-left-ten cursor-pointer"><IconInfoCircle class="form-tooltip-icon"/></span>
                  <span v-else class="margin-left-ten disabled cursor-pointer" v-tooltip.top-start="{ content: $orgMissingPermissions('The View Patient feature') }"><IconInfoCircle class="form-tooltip-icon"/></span>
                </div>
              </ion-col>
              <ion-col></ion-col>
              <ion-col size="auto">
                <button @click="goToPatientSettings()" class="prime-button button-clear pad-lr-five margin-lr-five" v-tooltip.top="'Go To Patient Overview'">
                  <ion-icon name="md-more"></ion-icon>
                </button>
                <!-- <button @click="showPatientOptionsPopover" class="prime-button button-clear" v-tooltip.top="'Go To Patient Settings'">
                <ion-icon name="md-more"></ion-icon>
              </button> -->
              </ion-col>
              <ion-col size="auto">
                <button v-if="isEnrolled" @click="goToConversation()" class="prime-button button-primary button-solo" v-tooltip="'Chat with this patient'">
                  <IconChatBubble class="prime-main-menu-icon" />
                </button>
                <button v-else class="prime-button button-primary disabled button-solo" v-tooltip="'Patient is not enrolled in LumerisEngage. <br/> Enroll patient to enable messaging'">
                  <IconChatBubble class="prime-main-menu-icon" />
                </button>
              </ion-col>
            </ion-row>
            <ion-row class="pad-top-ten pad-bottom-twenty" v-if="task && task.description">
              <ion-col class="full-height">
                <div class="text-small text-muted pad-bottom-ten">Task Description</div>
                <div v-html="task.description ? task.description : ''" style="white-space: pre-wrap;"></div>
              </ion-col>
            </ion-row>
          </ion-col>
          <ion-col class="margin-left-twenty full-height overflow-y-scroll mobile-2">
            <ion-row class="bottom-divider pad-top-ten pad-bottom-ten ion-align-items-center desktop">
              <ion-col size="auto">
                <div class="avatar current-patient-avatar margin-right-ten" v-if="task && task.assignedTo && task.assignedTo.firstName && task.assignedTo.lastName">
                  {{ task.assignedTo.firstName.charAt(0) + task.assignedTo.lastName.charAt(0) }}
                </div>
              </ion-col>
              <ion-col size="auto">
                <div class="text-small text-muted">Assigned To</div>
                <div class="text-bold cursor-pointer" @click="showAssignTaskPopover($event, task.id, availableProfessionals)">{{ makeAssignedName(task.assignedTo) }}</div>
              </ion-col>
              <ion-col></ion-col>
              <ion-col>
                <span class="text-center text-bold pill" :class="{ 'yellow-pill': showYellowTimeElapsedPill(task) }">
                  <TimeSince :startdate="task.createdAt" :enddate="task.lastUpdatedAt" :status="task.status"></TimeSince>
                </span>
              </ion-col>
            </ion-row>
            <ion-row class="bottom-divider ion-align-items-center desktop">
              <ion-col>
                <h4 class="text-bold">Task Comments:</h4>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <div class="center-center" v-if="commentsAsChat.length < 1">
                  <h5 class="text-muted">No comments to display</h5>
                </div>
                <div v-else>
                  <ChatBubble v-for="comment in commentsAsChat" :key="comment.id" :message="comment" orientation="full" color="none" :actions="showCommentActions(comment)" />
                </div>
              </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
      </div>
      <div class="column-stretch-footer">
        <ion-row class="ion-align-items-center pad-lr-twenty pad-top-ten pad-bottom-ten">
          <ion-col class="mobile-1">
            <ion-row class="ion-align-items-center" v-if="task && task.interaction && task.interaction.id != null">
              <ion-col>
                <button @click="openTranscriptModal(task.interaction.id, commentsAsChat)" class="prime-button button-secondary button-block">
                  View Conversation Transcript
                </button>
              </ion-col>
            </ion-row>
          </ion-col>
          <ion-col class="desktop">
            <TextAreaInput v-if="$can(I.MODIFY_TASK)" v-model="commentBody" placeholder="Leave a comment..." color="secondary" @save="saveComment" :isSaving="isSavingComment" />
            <ion-row v-if="commentError" class="pad-top-ten">
              <div :class="{ 'danger-well': commentError }" class="margin-bottom-ten notifications-well full-width">
                <span v-bind="commentError"></span>
              </div>
            </ion-row>
          </ion-col>
        </ion-row>
      </div>
      <div class="column-stretch-footer mobile">
        <ion-row class="ion-align-items-center pad-lr-twenty pad-top-ten pad-bottom-ten">
          <ion-col class="mobile-1">
            <ion-row class="ion-align-items-center">
              <ion-col class="pad-right-twenty">
                <button @click="displaySlideForMobile(2, 1)" class="prime-button button-secondary button-block">View Comments ({{ commentsAsChat.length }})</button>
              </ion-col>
              <ion-col>
                <button @click="closeModal(null)" class="prime-button button-block">Close</button>
                <!-- <ion-button mode="ios" @click="closeModal(null)" expand="full" color="primary" fill="clear" class="prime-button button-block">Close 111</ion-button> -->
              </ion-col>
            </ion-row>
          </ion-col>
          <ion-col class="mobile-2">
            <TextAreaInput v-if="$can(I.MODIFY_TASK)" v-model="commentBody" placeholder="Leave a comment..." color="secondary" @save="saveComment" :isSaving="isSavingComment" />
            <ion-row v-if="commentError" class="pad-top-ten">
              <div :class="{ 'danger-well': commentError }" class="margin-bottom-ten notifications-well full-width">
                <span v-bind="commentError"></span>
              </div>
            </ion-row>
            <ion-row class="pad-top-ten">
              <ion-col class="pad-right-twenty">
                <button @click="displaySlideForMobile(1, 2)" class="prime-button button-secondary button-block">View Details</button>
              </ion-col>
              <ion-col>
                <button @click="closeModal(null)" class="prime-button button-block">Close</button>
              </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
      </div>
    </div>
  </div>
</template>

<script>
import { addIcons } from "ionicons";
import { more } from "ionicons/icons";
import IconCheckCircle from "@/components/Global/Icons/IconCheckCircle";
import TimeSince from "@/components/Global/TimeSince";
import { send as httpSend } from "@/services/Api";
import ModalEditTextArea from "@/components/Global/ModalEditTextArea";
import PopoverCommentActions from "@/components/TaskDetails/PopoverCommentActions";
import TextAreaInput from "@/components/Global/TextAreaInput";
import ModalChatTranscript from "@/components/Chat/ModalChatTranscript";
import ChatBubble from "@/components/Chat/ChatBubble";
import store from "@/store";
import router from "@/router";
import IconDeleteTrash from "@/components/Global/Icons/IconDeleteTrash";
import IconInfoCircle from "@/components/Global/Icons/IconInfoCircle";
import PopoverPCPInfo from "@/components/Tasks/PopoverPcpInfo";
import PopoverPatientOptions from "@/components/Tasks/PopoverPatientOptions";
import PopoverAssignTask from "@/components/Tasks/PopoverAssignTask";
import { EventBus } from "@/services/Events";
import IconChatBubble from "@/components/Global/Icons/IconChatBubble";
import taskStatusesConfig from "./taskStatuses.config.json";

addIcons({
  "md-more": more.md
});
export default {
  name: "ModalTaskDescription",
  props: {
    taskId: undefined
  },
  components: {
    IconCheckCircle,
    TimeSince,
    TextAreaInput,
    ChatBubble,
    IconDeleteTrash,
    IconInfoCircle,
    IconChatBubble
  },
  created() {
    this.getComments(this.taskId);
    this.getAssignableProfessionals(this.taskId);
    this.getTaskById(this.taskId);
    EventBus.$on("taskAssigned", (taskId, professional) => {
      this.task.assignedTo = professional;
    });
    if (window.innerWidth < 756) {
      setTimeout(() => {
        const mobile2s = document.querySelectorAll(".mobile-2");
        mobile2s.forEach(el => (el.style.display = "none"));
      }, 200);
    }
  },
  data() {
    return {
      isLoading: false,
      commentsAsChat: [],
      comments: undefined,
      commentBody: undefined,
      commentError: undefined,
      task: undefined,
      selectedComment: {},
      isSavingComment: false,
      isMarkingCompleted: false,
      isMarkingInProgress: false,
      availableProfessionals: [],
      taskStatusesConfig,
      invalidTask: false
    };
  },
  computed: {
    isEnrolled() {
      return this.task.enrollmentStatus == "approved";
    }
  },
  methods: {
    goToConversation() {
      this.closeModal();
      store.commit("setPanelFlow", { prevPanel: "", currentPanel: "#convos-message" });
      this.$mobilize(".prime-active-conversations-container", "#convos-message", "mobile-show");
      router.push({
        path: "/professional?patient=" + this.task.beneficiary.id
      });
    },
    getAssignableProfessionals(id) {
      const method = "get";
      const path = document.config.tasksAPI + "/" + id + "/available/professional";

      httpSend({ path, method, authToken: true })
        .then(result => {
          this.availableProfessionals = result.data;
        })
        .catch(error => {
          this.catchError(error, "Could not get comments. Please try again later.");
        })
        .finally(() => (this.isLoadingComments = false));
    },
    getTaskById(id) {
      this.isLoading = true;
      const method = "get";
      const path = document.config.task + id;

      httpSend({ path, method, authToken: true })
        .then(result => {
          this.task = result.data;
          this.isLoading = false;
        })
        .catch(() => {
          this.invalidTask = true;
        })
        .finally(() => (this.isLoading = false));
    },
    getComments(id) {
      this.isLoadingComments = true;
      const method = "get";
      const path = document.config.task + id + "/comment";

      httpSend({ path, method, authToken: true })
        .then(result => {
          this.comments = result.data;
          this.commentsAsChat = result.data.map(comment => ({
            id: comment.id,
            commenter: comment.by.id,
            body: comment.text,
            created_at: comment.at,
            title: comment.by.prefix,
            first_name: comment.by.firstName,
            last_name: comment.by.lastName,
            suffix: comment.suffix
          }));
        })
        .catch(error => {
          this.catchError(error, "Could not get comments. Please try again later.");
        })
        .finally(() => (this.isLoadingComments = false));
    },
    saveComment(commentBody) {
      this.commentError = false;
      if (!commentBody) {
        this.commentError = "Please enter comment text";
        return;
      }

      this.isSavingComment = true;
      const method = "post";
      const path = document.config.task + this.task.id + "/comment";

      let payload = {
        comment: commentBody
      };

      httpSend({ path, method, payload, authToken: true })
        .then(result => {
          this.comments = result.data;
          this.getComments(this.task.id);
          this.selectedComment = {};
        })
        .catch(error => {
          this.catchError(error, "Could not save comment. Please try again later.");
        })
        .finally(() => {
          this.commentBody = "";
          this.isSavingComment = false;
        });
    },
    editComment(newComment, id) {
      this.commentError = false;
      if (!newComment) {
        this.commentError = "Please enter comment text";
        return;
      }

      const method = "put";
      const path = document.config.task + "comment/" + id;

      let payload = {
        comment: newComment
      };

      this.isSavingComment = true;

      httpSend({ path, method, payload, authToken: true })
        .then(() => {
          this.getComments(this.task.id);
          this.selectedComment = undefined;
        })
        .catch(error => {
          this.catchError(error, "Could not edit comment. Please try again later.");
        })
        .finally(() => (this.isSavingComment = false));
    },
    deleteComment(id) {
      const method = "delete";
      const path = document.config.task + "comment/" + id;

      httpSend({ path, method, authToken: true })
        .then(() => {
          this.getComments(this.task.id);
        })
        .catch(error => {
          this.catchError(error, "Could not delete comment. Please try again later.");
        });
    },
    showCommentActions(comment) {
      const prof = store.getters["currentProfessional/get"].professional;
      if (prof.id !== comment.commenter) {
        return null;
      }
      return {
        component: PopoverCommentActions,
        events: {
          edit: () => {
            this.selectedComment = this.comments.find(c => c.id == comment.id);
            this.openEditCommentModal(comment.body, comment.id);
          },
          delete: () => {
            this.deleteComment(comment.id);
          }
        }
      };
    },
    openEditCommentModal(comment, id) {
      return this.$ionic.modalController
        .create({
          component: ModalEditTextArea,
          componentProps: {
            propsData: {
              title: "Edit Comment",
              instructions: "Edit your comment here and then click 'Save Edit' below.",
              value: comment,
              id: id,
              onSave: this.editComment,
              validators: [value => (!value ? "Please enter comment text" : null)]
            }
          }
        })
        .then(m => m.present());
    },
    closeModal: function() {
      EventBus.$emit("tasks_clean_url");
      this.$ionic.modalController.dismiss({ description: null });
    },
    makeAssignedName(rowObj) {
      let title;

      if (rowObj.prefix) {
        title = rowObj.prefix + " ";
      } else if (rowObj.title) {
        title = rowObj.title + " ";
      }

      let fName = rowObj.firstName ? rowObj.firstName + " " : "";
      let lName = rowObj.lastName ? rowObj.lastName : "";

      let assignedName = (title ? title + " " : "") + fName + lName;

      return assignedName != "" ? assignedName : "Care Team";
    },
    showYellowTimeElapsedPill(task) {
      return task !== null && this.isTaskActive(task);
    },
    isTaskActive(task) {
      return task.status == "created" || task.status == "assigned" || task.status == "in_progress";
    },
    confirmCancel(id, val) {
      this.$ionic.alertController
        .create({
          header: "Confirm Task Cancellation",
          message: "Are you sure you want to cancel this task?",
          mode: "ios",
          buttons: [
            {
              text: "No",
              role: "cancel",
              cssClass: "secondary",
              handler: () => {}
            },
            {
              text: "Yes",
              handler: () => {
                this.updateStatus(id, val);
              }
            }
          ]
        })
        .then(a => a.present());
    },
    async updateStatus(taskId, value) {
      value == "completed" ? (this.isMarkingCompleted = true) : (this.isMarkingInProgress = true);
      this.isTableLoading = true;
      const data = {
        id: taskId,
        action: "status",
        payload: {
          status: value
        },
        callback: this.showToast
      };
      await store.dispatch("tasks/updateSingleTask", data);
      EventBus.$emit("getTasks");
      this.closeModal();
    },
    openTranscriptModal: async function(id, comments) {
      this.$ionic.modalController
        .create({
          component: ModalChatTranscript,
          mode: "ios",
          componentProps: {
            propsData: {
              title: "Outreach Transcript",
              interactionId: id,
              comments: comments
            }
          }
        })
        .then(m => m.present());
    },
    showPopover(ev, rowObj) {
      this.$ionic.popoverController
        .create({
          component: PopoverPCPInfo,
          event: ev,
          mode: "ios",
          componentProps: {
            data: {
              propData: rowObj
            }
          }
        })
        .then(m => m.present());
    },
    showAssignTaskPopover(ev, taskId, availableProfessionals) {
      this.$ionic.popoverController
        .create({
          component: PopoverAssignTask,
          event: ev,
          mode: "ios",
          componentProps: {
            data: {
              taskId: taskId,
              availableProfessionals: availableProfessionals
            }
          }
        })
        .then(m => m.present());
    },
    goToPatientSettings() {
      router.push({
        path: "/patient-settings/general-info/" + this.task.beneficiary.id
      });
      this.$ionic.modalController.dismiss();
    },
    showPatientOptionsPopover(ev, beneId) {
      //future state, for when patient dashboard is created
      this.$ionic.popoverController
        .create({
          component: PopoverPatientOptions,
          event: ev,
          mode: "ios",
          componentProps: {
            data: {
              propData: beneId
            }
          }
        })
        .then(m => m.present());
    },
    updateStatusPerTask(e) {
      this.updateStatus(this.task.id, e.detail.value);
    },
    displaySlideForMobile(toShow, toHide) {
      const mobileSlideToShow = document.querySelectorAll(`.mobile-${toShow}`);
      const mobileSlideToHide = document.querySelectorAll(`.mobile-${toHide}`);
      mobileSlideToShow.forEach(el => (el.style.display = ""));
      mobileSlideToHide.forEach(el => (el.style.display = "none"));
    }
  }
};
</script>

<style scoped>
ion-textarea {
  background: #ffffff;
  border: 2px solid #ffffff;
  border-radius: 8px;
  padding: 12px 15px;
  font-size: 14px;
  font-family: var(--ion-font-family);
  font-weight: 500;
  line-height: 1.5;
  transition: border 0.2s ease-in-out;
}
ion-textarea textarea {
  padding: 0 !important;
}
ion-textarea:hover {
  background: var(--ion-color-light);
}
ion-textarea:focus {
  background: #ffffff;
  border: 2px solid var(--ion-color-primary);
}

ion-footer {
  padding: 20px;
}
ion-footer ion-col:first-child {
  padding-right: 5px;
}
ion-footer ion-col:not(:first-child) {
  padding-left: 5px;
}
.column-stretch-wrap {
  display: flex;
  flex-direction: column;
}

.column-stretch-header {
  flex: 0 0 auto;
}

.column-stretch-body {
  flex: 1 1 auto;
  position: relative; /* need this to position inner content */
  overflow: hidden;
}

.column-stretch-footer {
  flex: 0 0 auto;
}

.pad-lr-five {
  padding-right: 5px;
  padding-left: 5px;
}

@media screen and (max-width: 768px) {
  .mobile-1.mob-overwrite {
    padding-right: 0 !important;
    border: 0 !important;
  }
}

ion-select {
  padding: 10px 5px 5px 0;
}

/* works only with ionic6+ */

ion-select::part(text) {
  font-weight: bold !important;
}

.disabled {
  color: var(--ion-color-medium) !important;
}

.disabled svg {
  fill: var(--ion-color-medium) !important;
  cursor: not-allowed;
}
.button-fixed {
  width: 150px;
}
</style>
