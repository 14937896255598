<template>
  <ion-grid class="full-width pad-five">
    <ion-row>
      <ion-col class="pad-ten">
        <div class="text-small text-muted">Gender:</div>
        <div class="text-semibold text-capitalize">{{ propData.beneficiary.gender ? propData.beneficiary.gender : "N/A" }}</div>
      </ion-col>
      <ion-col class="pad-ten">
        <div class="text-small text-muted">Date of Birth:</div>
        <div class="text-semibold">{{ dob }}</div>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col class="pad-ten">
        <div class="text-small text-muted">Phone #:</div>
        <div class="text-semibold">{{ phone }}</div>
      </ion-col>
      <ion-col class="pad-ten">
        <div class="text-small text-muted">PCP:</div>
        <div class="text-semibold">{{ (propData.pcp.title ? propData.pcp.title + " " : "") + (propData.pcp.firstName ? propData.pcp.firstName + " " : "") + (propData.pcp.lastName ? propData.pcp.lastName + " " : "") }}</div>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col class="pad-ten">
        <div class="text-small text-muted">ID:</div>
        <div class="text-semibold">{{ propData.beneficiary.mrn ? propData.beneficiary.mrn : "N/A" }}</div>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script>
import displayPhoneNumber from "@/utils/FormHelpers/displayPhoneNumber";

export default {
  name: "PopoverPcpInfo",
  computed: {
    dob() {
      let dob = this.propData.beneficiary.dob;
      dob ? (dob = this.$moment.utc(dob).format("M/DD/YYYY")) : (dob = "N/A");
      return dob;
    },
    phone() {
      let phoneNum = this.propData.phoneNumber ? displayPhoneNumber(this.propData.phoneNumber) : "N/A";
      return phoneNum;
    }
  }
};
</script>
