<template>
  <div>
    <ion-row>
      <ion-col class="pad-lr-ten pad-top-twenty">
        <input id="searchSpot" ref="searchSpot" autocomplete="off" class="form-control" v-model="name" placeholder="Search..." @keyup="filterInput" />
      </ion-col>
    </ion-row>
    <ion-list mode="ios" ref="assignedList" class="assigned-list">
      <div v-if="availableProfessionals.length < 1" lines="none" class="text-muted text-center pad-twenty">
        No available care team members
      </div>
      <ul class="professional-list" v-else>
        <li v-for="(professional, index) in filteredProfessionals" :key="professional.id" lines="none" @mouseover="hoverSelect(index)" @click="confirmAssignTo(taskId, professional)" :class="index === selectedIndex ? 'pcp-selected-item curor-pointer' : 'curor-pointer'">
          <ion-label v-html="highLight(professional.firstName) + ' ' + highLight(professional.lastName)"></ion-label>
        </li>
      </ul>
    </ion-list>
  </div>
</template>

<script>
import { EventBus } from "@/services/Events";

export default {
  name: "PopoverAssignTask",
  data() {
    return {
      taskId: Number,
      name: "",
      availableProfessionals: Array,
      assignToProfessionalName: undefined,
      filteredProfessionals: [],
      selectedIndex: -1,
      cTaskId: 0,
      searchSpot: null,
      searchedProfessional: ""
    };
  },
  beforeMount() {
    this.availableProfessionals = this.availableProfessionals.sort((a, b) => a.firstName.localeCompare(b.firstName));
    this.filteredProfessionals = this.availableProfessionals;
    this.cTaskId = this.taskId;
  },
  mounted() {
    setTimeout(() => {
      this.$refs.searchSpot.focus();
      this.searchSpot = document.getElementById("searchSpot");
    }, 300);
  },
  methods: {
    highLight(cInput) {
      const regex = new RegExp(this.name, "i");
      let matchingString = cInput.match(regex);
      return matchingString ? cInput.replace(matchingString, `<strong>${matchingString}</strong>`) : cInput;
    },
    filterInput(e) {
      this.searchedProfessional = "";
      const regex = new RegExp(this.name, "gi");
      if (this.name !== "") {
        let tempFilterProfessionals = this.availableProfessionals.filter(entry => entry.firstName.match(regex) || entry.lastName.match(regex));
        if (this.filteredProfessionals.length !== tempFilterProfessionals.length) {
          this.selectedIndex = -1;
        }
        this.filteredProfessionals = tempFilterProfessionals;
      } else {
        this.filteredProfessionals = this.availableProfessionals;
      }
      if (e.key === "ArrowDown") {
        this.selectedIndex = this.filteredProfessionals.length - 1 === this.selectedIndex ? 0 : this.selectedIndex + 1;
        this.searchedProfessional = this.filteredProfessionals[this.selectedIndex].firstName + " " + this.filteredProfessionals[this.selectedIndex].lastName;
        this.searchSpot.placeholder = this.searchedProfessional;
      }
      if (e.key === "ArrowUp") {
        this.selectedIndex = this.selectedIndex > 0 ? this.selectedIndex - 1 : this.filteredProfessionals.length - 1;
        this.searchedProfessional = this.filteredProfessionals[this.selectedIndex].firstName + " " + this.filteredProfessionals[this.selectedIndex].lastName;
        this.searchSpot.placeholder = this.searchedProfessional;
      }

      if (e.key === "Enter") {
        this.name = this.searchedProfessional;
        this.confirmAssignTo(this.cTaskId, this.filteredProfessionals[this.selectedIndex]);
      }
    },
    hoverSelect(i) {
      this.selectedIndex = i;
      this.searchedProfessional = this.filteredProfessionals[this.selectedIndex].firstName + " " + this.filteredProfessionals[this.selectedIndex].lastName;
      this.searchSpot.placeholder = this.searchedProfessional;
    },
    confirmAssignTo(taskId, professional) {
      this.assignToProfessionalName = professional.firstName + " " + professional.lastName;
      this.$ionic.alertController
        .create({
          header: "Confirm Task Assignment",
          message: "Assign this task to " + this.assignToProfessionalName + "?",
          mode: "ios",
          buttons: [
            {
              text: "No",
              role: "cancel",
              cssClass: "secondary",
              handler: () => {}
            },
            {
              text: "Yes",
              handler: () => {
                this.updateAssignedTo(taskId, professional);
              }
            }
          ]
        })
        .then(a => a.present());
    },
    updateAssignedTo: async function(taskId, professional) {
      EventBus.$emit("taskAssigned", taskId, professional);
      this.$ionic.popoverController.dismiss();
    }
  }
};
</script>
<style scoped>
ion-list.list-ios {
  margin-bottom: 0;
}
ion-item {
  padding: 0 5px;
}
ion-item:hover {
  background: var(--ion-color-light);
}
ion-label {
  font-weight: 500;
}
ul.professional-list {
  list-style-type: none;
  padding: 0;
  max-height: 300px;
  overflow-y: scroll;
  margin-bottom: 0;
}

.professional-list li {
  padding: 10px 20px;
  font-size: 16px;
}

.professional-list li:hover {
  background: var(--ion-color-light);
  cursor: pointer;
}

.pcp-selected-item {
  background: var(--ion-color-light);
}
</style>
