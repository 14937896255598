<template>
  <div class="prime-modal">
    <ion-header class="bottom-divider">
      <ion-toolbar>
        <IconPaperPlane class="modal-header-icon" slot="start" name="md-paper-plane-outline" />
        <h5 class="pad-left-ten">Quick Replies</h5>
        <ion-button
          slot="end"
          mode="ios"
          @click="closeModal()"
          expand="full"
          color="primary"
          fill="clear"
          ><ion-icon name="md-close"></ion-icon
        ></ion-button>
      </ion-toolbar>
      <ion-toolbar>
        <ion-row v-if="stepNumber == 1">
          <span class="pad-five"
            >Quickly access commonly used replies. Click "Load" to load a reply into the message
            field.</span
          >
        </ion-row>
        <!-- {{ replies.length }}
        {{ replies }} -->
        <ion-row v-if="stepNumber == 2">
          <span class="pad-five"
            >Quickly access commonly used replies. Add a reply template for quick use in future
            messages.</span
          >
        </ion-row>
        <div v-if="stepNumber == 1">
          <ion-row class="margin-top-ten margin-bottom-ten">
            <ion-col>
              <div class="form-control-search valign">
                <input
                  class="full-width"
                  v-model="searchString"
                  placeholder="Search for message..."
                  @input="debounceSearch($event)"
                />
                <IconSearch class="icon-small icon-grey" />
              </div>
            </ion-col>
            <ion-col v-if="$can(I.MODIFY_USER_TEMPLATE)" class="margin-left-ten edit-button">
              <button
                class="prime-button button-block button-secondary"
                @click="editMode = !editMode"
              >
                {{ editMode ? "Stop Editing" : "Edit" }}
              </button>
            </ion-col>
            <ion-col v-else class="margin-left-ten edit-button">
              <button
                class="prime-button disabled button-block button-secondary"
                v-tooltip.top-start="{
                  content: $orgMissingPermissions('The Modifying Template feature')
                }"
              >
                {{ editMode ? "Stop Editing" : "Edit" }}
              </button>
            </ion-col>
          </ion-row>
        </div>
      </ion-toolbar>
    </ion-header>

    <ion-content
      v-if="stepNumber == 1"
      class="margin-lr-ten prime-modal-content center-items"
      color="light"
    >
      <div v-for="reply in replies" :key="reply.id" class="">
        <div>
          <ion-row class="margin-top-fifteen">
            Topic:
          </ion-row>
          <ion-row>
            <ion-col class="margin-right-ten">
              <input type="text" v-if="editMode" class="form-control" v-model="reply.topic" />

              <h6 v-else>
                {{ reply.topic }}
              </h6>
            </ion-col>

            <ion-col @click="moveReplyUp(reply)" size="auto">
              <IconReorderUp
                class="icon-medium icon-grey reply-icon cursor-pointer"
                v-if="editMode && !filtered && reply.displayOrder != replies[0].displayOrder"
              />
            </ion-col>
            <ion-col @click="moveReplyDown(reply)" size="auto">
              <IconReorderDown
                class="icon-medium icon-grey reply-icon cursor-pointer"
                v-if="
                  editMode &&
                    !filtered &&
                    reply.displayOrder != replies[replies.length - 1].displayOrder
                "
              />
            </ion-col>
          </ion-row>
        </div>
        <ion-card class="margin-death full-width prime-card" color="white">
          <ion-row class="margin-bottom-ten margin-top-ten">
            <ion-col class="margin-right-fifteen margin-left-ten">
              <div class="qr-message-balloon full-width" :class="baloonStyle">
                <textarea
                  v-if="editMode"
                  maxlength="500"
                  class="form-control margin-top-twenty textarea"
                  rows="3"
                  v-model="reply.body"
                ></textarea>
                <div v-else>
                  {{ reply.body }}
                </div>
              </div>
            </ion-col>
            <ion-col class="load-button margin-lr-fifteen ion-align-self-bottom">
              <div class="column-stretch-wrap full-height">
                <div class="column-stretch-header">
                  <ion-row>
                    <ion-col> </ion-col>
                    <ion-col size="auto">
                      <ion-icon
                        class="icon-grey cursor-pointer"
                        v-if="editMode"
                        mode="ios"
                        @click="deleteReply(reply.id)"
                        name="md-close"
                      ></ion-icon>
                    </ion-col>
                  </ion-row>
                </div>
                <div class="column-stretch-body"></div>
                <div class="column-stretch-footer">
                  <button
                    v-if="!editMode"
                    class="prime-button button-block"
                    @click="loadConversation(reply.body)"
                  >
                    Load
                  </button>
                  <button v-else class="prime-button button-block" @click="updateTemplate(reply)">
                    Save
                  </button>
                </div>
              </div>
            </ion-col>
          </ion-row>
        </ion-card>
      </div>
      <h6 class="text-center pad-bottom-ten"></h6>
    </ion-content>

    <ion-content
      v-if="stepNumber == 2"
      class="margin-lr-ten prime-modal-content center-items"
      color="light"
    >
      <div class="margin-lr-ten"></div>
      <ion-row class="margin-bottom-ten margin-top-ten">
        New Topic:
        <textarea
          class="form-control margin-top-twenty margin-bottom-ten"
          rows="2"
          v-model="newTopic"
        ></textarea>

        Response Message:
        <textarea
          class="form-control margin-top-twenty text-black"
          rows="5"
          v-model="newMessage"
        ></textarea>
      </ion-row>
    </ion-content>

    <ion-row v-if="$can(I.MODIFY_USER_TEMPLATE)" class="margin-top-ten prime-modal-content">
      <button
        v-if="stepNumber == 1"
        class="prime-button button-block button-secondary margin-bottom-ten"
        @click="stepNumber = 2"
      >
        Add New Template
      </button>
      <button
        v-if="stepNumber == 2"
        class="prime-button button-block margin-bottom-ten"
        @click="addConversation()"
      >
        Add Conversation To Templates
      </button>
      <button
        v-if="stepNumber == 2"
        class="prime-button button-block button-secondary margin-bottom-ten"
        @click="(stepNumber = 1), (newTopic = ''), (newMessage = '')"
      >
        Cancel and Return
      </button>
    </ion-row>
  </div>
</template>

<script>
import IconSearch from "../Global/Icons/IconSearch.vue";
import IconReorderUp from "../Global/Icons/IconReorderUp.vue";
import IconReorderDown from "../Global/Icons/IconReorderDown.vue";
import IconPaperPlane from "../Global/Icons/IconPaperPlane.vue";
import { EventBus } from "@/services/Events";
import { send as httpSend } from "@/services/Api";
import _ from "lodash";
import { addIcons } from "ionicons";
import { link } from "ionicons/icons";

addIcons({
  "md-link": link.md
});

export default {
  name: "QuickReplyOptions",
  components: {
    IconSearch,
    IconPaperPlane,
    IconReorderUp,
    IconReorderDown
  },
  props: {
    actions: Object,
    message: Object,
    orientation: String,
    color: String,
    timestamp: String
  },
  mounted() {
    this.getTemplatedMessages();
  },
  data() {
    return {
      stepNumber: 1,
      searchString: "",
      newTopic: "",
      newMessage: "",
      organizationId: this.claims ? this.claims.orgs : undefined,
      filtered: false,
      replies: [],
      editMode: false
    };
  },
  created: function() {
    EventBus.$on("messageChannel", this.setMessagechannel);
  },
  methods: {
    getTemplatedMessages: async function() {
      const method = "get";
      const path = document.config.templateMessagesApi;

      httpSend({ path, method, authToken: true })
        .then(response => {
          this.replies = response.data;
        })
        .catch(error => {
          this.$ionic.toastController
            .create({
              header: "Could not get Quick Replies. Please try again later.",
              message: error,
              duration: 5000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        });
    },
    debounceSearch: _.debounce(function(evt) {
      this.filtered = true;
      if (evt.target.value == "") {
        this.filtered = false;
        this.getTemplatedMessages();
      } else {
        let terms = evt.target.value;
        const method = "get";
        const path = document.config.templateMessagesSearchApi + terms;

        httpSend({ path, method, authToken: true })
          .then(response => {
            this.replies = response.data;
          })
          .catch(error => {
            this.$ionic.toastController
              .create({
                header: "Could not search Quick Replies. Please try again later.",
                message: error,
                duration: 5000,
                position: "top"
              })
              .then(m => m.present());
            khanSolo.log(error);
          });
      }
    }, 500),
    closeModal: function() {
      this.$ionic.modalController.dismiss({ description: null });
    },
    updateTemplate: function(reply) {
      const method = "put";
      const path = document.config.templateMessagesApi;
      const payload = reply;
      httpSend({ path, method, payload, authToken: true })
        .then(() => {
          this.$ionic.toastController
            .create({
              header: "Success",
              message: "The templated message has been updated.",
              duration: 4000,
              color: "primary",
              mode: "ios"
            })
            .then(m => m.present());
          this.getTemplatedMessages();
        })
        .catch(error => {
          this.$ionic.toastController
            .create({
              header: "Could not update template. Please try again later.",
              message: error,
              duration: 5000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        });
    },
    updateTemplateDirection: function(id, direction) {
      const method = "put";
      const path = document.config.templateMessagesApi + id + "/sort/" + direction;

      httpSend({ path, method, authToken: true })
        .then(() => {
          this.$ionic.toastController
            .create({
              header: "Success",
              message: "The templated message has been updated.",
              duration: 4000,
              color: "primary",
              mode: "ios"
            })
            .then(m => m.present());
          this.getTemplatedMessages();
        })
        .catch(error => {
          this.$ionic.toastController
            .create({
              header: "Could not update template. Please try again later.",
              message: error,
              duration: 5000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        });
    },
    moveReplyUp: function(reply) {
      this.updateTemplateDirection(reply.id, "up/");
    },
    moveReplyDown: function(reply) {
      this.updateTemplateDirection(reply.id, "down/");
    },
    loadConversation: function(msg) {
      EventBus.$emit("loadTemplateMessage", msg);
      this.closeModal();
    },
    deleteReply: function(id) {
      const method = "delete";
      const path = document.config.templateMessagesApi + id;
      httpSend({ path, method, authToken: true })
        .then(() => {
          this.$ionic.toastController
            .create({
              header: "Success",
              message: "The templated message has successfully deleted.",
              duration: 4000,
              color: "primary",
              mode: "ios"
            })
            .then(m => m.present());
          this.getTemplatedMessages();
        })
        .catch(error => {
          this.$ionic.toastController
            .create({
              header: "Could not delete this quick reply. Please try again later.",
              message: error,
              duration: 5000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        });
    },
    addConversation: function() {
      const method = "post";
      let payload = {
        topic: this.newTopic,
        body: this.newMessage
      };

      let path = document.config.templateMessagesApi;
      httpSend({ path, method, payload, authToken: true })
        .then(response => {
          this.$ionic.toastController
            .create({
              header: "Success",
              message: "The new templated message has been created.",
              duration: 4000,
              color: "primary",
              mode: "ios"
            })
            .then(m => m.present());
          this.replies = response.data;
        })
        .catch(error => {
          this.$ionic.toastController
            .create({
              header: "Could not add new template. Please try again later.",
              message: error,
              duration: 5000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        })
        .finally(() => {
          this.stepNumber = 1;
          this.getTemplatedMessages();
          this.newMessage = "";
          this.newTopic = "";
        });
    }
  },
  computed: {
    baloonStyle() {
      let color = this.color;
      let orientation = this.orientation;
      color = "blue";
      orientation = "right";

      return [color, orientation];
    },
    messageDirection() {
      var msgDirection = this.message.direction;
      if (this.isPatient) {
        if (msgDirection === "incoming") {
          msgDirection = "outgoing";
        } else if (msgDirection === "outgoing") {
          msgDirection = "incoming";
        }
      }
      return msgDirection;
    }
  }
};
</script>

<style>
.message-wrap {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.message-wrap.left {
  padding-right: 20%;
  align-items: flex-start;
}

.message-wrap.right {
  padding-left: 20%;
  align-items: flex-end;
}

.message-wrap.center {
  text-align: center;
}

.message-wrap.none {
  border-bottom: 2px solid var(--ion-background-color);
}

.qr-message-balloon.left {
  border-bottom-left-radius: 0;
}

.qr-message-balloon.white {
  background-color: #ffffff;
  border: 2px solid var(--ion-color-light-shade);
}

.qr-message-balloon.white a {
  color: var(--ion-color-primary);
}

.qr-message-balloon.right {
  border-bottom-right-radius: 0;
}

.qr-message-balloon.blue,
.qr-message-balloon.blue a {
  background-color: var(--ion-color-primary);
  color: #ffffff !important;
}

.chat-info {
  margin-top: 5px;
}

.qr-message-balloon .chat-info {
  margin-top: 13px;
}
/* Used */

.reply-icon:hover {
  fill: var(--ion-color-primary);
}

ion-textarea {
  background: #ffffff;
  border: 2px solid #ffffff;
  border-radius: 8px;
  padding: 12px 15px;
  font-size: 14px;
  font-family: var(--ion-font-family);
  color: black;
  font-weight: 500;
  line-height: 1.5;
  transition: border 0.2s ease-in-out;
}
textarea {
  color: black !important;
}
ion-textarea:hover {
  background: var(--ion-color-light);
}
ion-textarea:focus {
  background: #ffffff;
  border: 2px solid var(--ion-color-primary);
}

.qr-message-balloon {
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  max-width: 100%;
  min-width: 80%;
  padding: 15px;
  border-radius: 20px;
}

.search-bar {
  max-width: 80%;
}

.edit-button {
  max-width: 20%;
}

.card-color {
  color: var(--ion-color-secondary);
}

.load-button {
  max-width: 15%;
  vertical-align: bottom;
}

.column-stretch-wrap {
  display: flex;
  flex-direction: column;
}

.column-stretch-header {
  flex: 0 0 auto;
}

.column-stretch-body {
  flex: 1 1 auto;
  position: relative; /* need this to position inner content */
}

.column-stretch-footer {
  flex: 0 0 auto;
}

.modal-header-icon {
  width: 20px !important;
  height: 20px;
}
textarea {
  resize: vertical;
}
</style>
