<template>
  <ion-list mode="ios">
    <ion-item lines="inset" @click="copy(false)" class="cursor-pointer">
      <ion-label color="dark" mode="ios">Copy Transcript</ion-label>
    </ion-item>
    <ion-item lines="none" @click="copy(true)" class="cursor-pointer">
      <ion-label color="dark" mode="ios">Copy Transcript and Task Comments</ion-label>
    </ion-item>
    <div id="__all-text">
      <div id="__transcript-text">
        ---------- LumerisEngage Automated Conversation Transcript ----------
        <div v-for="msg in chat" :key="msg.id">
          <span>{{ msg.getDisplayName() }} - </span>
          <span>{{ msg.getTimestamp() }}</span>
          <span v-if="msg.getChannel()"> - via {{ msg.getChannel() }}</span>
          <div>"{{ msg.body }}"</div>
          --
        </div>
      </div>
      <br />
      <br />
      <div>
        ----------
        <br />
        Task Comments
        <br />
        ----------
        <br />
        <div v-for="comment in commentary" :key="comment.id">
          <span>{{ comment.getDisplayName() }} - </span>
          <span>{{ comment.getTimestamp() }}</span>
          <span v-if="comment.getChannel()"> - via {{ comment.getChannel() }}</span>
          <div>"{{ comment.body }}"</div>
          --
        </div>
      </div>
    </div>
  </ion-list>
</template>

<script>
import utils from "@/utils/messageUtils";

export default {
  name: "PopoverCopyToClipboard",
  props: {
    messages: Array,
    comments: Array
  },
  computed: {
    chat() {
      return this.convert(this.messages);
    },
    commentary() {
      return this.convert(this.comments);
    }
  },
  methods: {
    convert(messages) {
      if (!messages || messages.length == 0) {
        return "";
      }

      return messages.map(msg => utils.wrap(msg));
    },
    copy(withComments) {
      window.getSelection().removeAllRanges(); // clear current selection

      const id = withComments ? "__all-text" : "__transcript-text";

      const transcriptRange = document.createRange();
      transcriptRange.selectNodeContents(document.getElementById(id));
      window.getSelection().addRange(transcriptRange); // to select text

      document.execCommand("copy");
      window.getSelection().removeAllRanges(); // to deselect

      this.$ionic.toastController
        .create({
          header: "Copied!",
          message: "The chat transcript has been copied to your clipboard",
          duration: 4000,
          color: "primary",
          mode: "ios"
        })
        .then(m => m.present());

      this.$ionic.popoverController.dismiss();
    }
  }
};
</script>

<style scoped>
ion-list.list-ios {
  margin-bottom: 0;
}
ion-item {
  padding: 0 5px;
}
ion-item:hover {
  background: var(--ion-color-light);
}
ion-label {
  font-weight: 500;
}
#__all-text {
  position: fixed;
  width: 1px;
  height: 1px;
  left: -9999px;
}
</style>
