<template>
  <div class="prime-modal">
    <ion-header>
      <ion-toolbar>
        <IconEditPencil slot="start" class="icon-small" />
        <h5 slot="start">{{ title }}</h5>
        <ion-button slot="end" mode="ios" @click="closeModal()" expand="full" color="medium" fill="clear"><ion-icon name="md-close"></ion-icon></ion-button>
      </ion-toolbar>
    </ion-header>
    <ion-content class="prime-modal-content">
      <div :class="{ 'has-errors': errors.length > 0 }" class="edit-text-container">
        <ion-row v-if="errors.length > 0">
          <div class="danger-well margin-bottom-ten notifications-well full-width">
            <div v-for="error in errors" :key="error">{{ error }}</div>
          </div>
        </ion-row>
        <p class="instructions">{{ instructions }}</p>
        <ion-col>
          <textarea-autosize class="form-control edit-text-input" ref="textAreaInput" rows="1" v-model="data" @keydown.native.enter.exact.prevent @keyup.native.enter.exact="save" />
        </ion-col>
      </div>
    </ion-content>
    <ion-footer>
      <ion-grid>
        <ion-row>
          <ion-col class="margin-right-ten">
            <button @click="closeModal()" class="prime-button button-secondary button-block">
              Cancel
            </button>
          </ion-col>
          <ion-col>
            <button @click="save()" class="prime-button button-block" :class="{ 'button-pending': isSaving }">
              <ion-spinner name="dots" duration="1200" color="white"></ion-spinner>
              <span>Save Edit</span>
            </button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-footer>
  </div>
</template>

<script>
import IconEditPencil from "@/components/Global/Icons/IconEditPencil";

export default {
  name: "ModalEditTextArea",
  props: {
    title: String,
    instructions: String,
    value: String,
    isSaving: Boolean,
    onSave: Function,
    validators: Array,
    id: Number
  },
  components: {
    IconEditPencil
  },
  data() {
    return {
      data: this.value,
      errors: []
    };
  },
  methods: {
    closeModal() {
      this.$ionic.modalController.dismiss();
    },
    save() {
      if (this.validators && this.validators.length > 0) {
        this.errors = this.validators.map(validator => validator(this.data)).filter(error => !!error);
      }
      if (this.errors.length > 0) {
        return;
      }

      this.onSave && this.onSave(this.data, this.id);
      this.$ionic.modalController.dismiss();
    }
  }
};
</script>

<style scoped>
.instructions {
  color: var(--ion-color-medium-shade);
  font-size: 11px;
  margin-bottom: 8px;
}

.edit-text-container {
  height: calc(100% - 48px);
}

.edit-text-container.has-errors {
  height: calc(100% - 98px);
}

.edit-text-input {
  height: 100% !important;
}

ion-footer {
  padding: 20px;
}
</style>
