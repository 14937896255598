<template>
  <ion-list mode="ios">
    <ion-item lines="inset" @click="executeEdit()" class="cursor-pointer">
      <ion-label mode="ios">Edit Comment</ion-label>
      <IconEditPencil slot="end" class="icon-small" />
    </ion-item>
    <ion-item lines="none" @click="executeDelete()" class="cursor-pointer">
      <ion-label color="danger" mode="ios">Delete Comment</ion-label>
      <IconDeleteTrash slot="end" class="icon-danger icon-small" />
    </ion-item>
  </ion-list>
</template>

<script>
import IconEditPencil from "@/components/Global/Icons/IconEditPencil";
import IconDeleteTrash from "@/components/Global/Icons/IconDeleteTrash";

export default {
  name: "PopoverCommentActions",
  components: {
    IconEditPencil,
    IconDeleteTrash
  },
  methods: {
    executeEdit() {
      this.events.edit && this.events.edit();
      this.$ionic.popoverController.dismiss();
    },
    executeDelete() {
      this.events.delete && this.events.delete();
      this.$ionic.popoverController.dismiss();
    }
  }
};
</script>

<style scoped>
.icon-danger {
  fill: var(--ion-color-danger);
}
ion-list.list-ios {
  margin-bottom: 0;
}
ion-item {
  padding: 0 5px;
}
ion-item:hover {
  background: var(--ion-color-light);
}
ion-label {
  font-weight: 500;
}
</style>
