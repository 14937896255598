<template>
  <div class="prime-popover prime-modal">
    <ion-header>
      <ion-toolbar>
        <IconChatBubble slot="start" class="icon-small" />
        <h5 slot="start">Edit Conversation Title</h5>
        <ion-button
          slot="end"
          mode="ios"
          @click="dismissPopover()"
          expand="full"
          color="primary"
          fill="clear"
          ><ion-icon name="md-close"></ion-icon
        ></ion-button>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div>
        <h6>Conversation title:</h6>
        <p class="prime-form-subtitle">
          Please provide a short, descriptive title that summarizes the topic of this conversation.
        </p>
        <input
          class="form-control"
          :class="{ 'validation-error': error }"
          v-model="messageTitle"
          placeholder="Set Message Title..."
        />
      </div>
      <button class="prime-button button-block margin-top-ten" @click="editConversationTitle()">
        Update Conversation Title
      </button>
    </ion-content>
  </div>
</template>

<script>
import IconChatBubble from "@/components/Global/Icons/IconChatBubble";
import { send as httpSend } from "@/services/Api";
import { EventBus } from "@/services/Events.js";

export default {
  name: "MessageEditConvoSubject",
  components: {
    IconChatBubble
  },
  data() {
    return {
      interactionId: undefined,
      messageTitle: undefined,
      error: undefined,
      initiator: undefined,
      status: undefined,
      msgChannel: undefined
    };
  },
  methods: {
    async editConversationTitle() {
      // reset error
      this.error = false;

      if (!this.messageTitle) {
        this.error = true;
      } else {
        const method = "PUT";
        const path = document.config.messageApi;

        const claims = await this.$authState.claims();

        const payload = {
          beneficiary_id: this.beneficiaryId,
          clientPartnerReviewed: true,
          clientPartnerReviewedAt: null,
          id: this.interactionId,
          initiator: this.initiator,
          professional_id: claims.professionalId,
          status: this.status,
          subject: this.messageTitle,
          urgency: this.urgency
        };

        try {
          await httpSend({ path, method, payload, authToken: true });
        } catch (error) {
          khanSolo.log(error);
        }

        this.$ionic.popoverController.dismiss();
        EventBus.$emit(
          "conversationUpdated",
          this.interactionId,
          this.msgChannel,
          this.messageTitle
        );
      }
    },
    dismissPopover() {
      this.$ionic.popoverController.dismiss();
    }
  }
};
</script>
<style scoped>
.validation-error {
  border: 2px solid rgb(198, 0, 0);
}
</style>
