<template>
  <ion-list>
    <ion-item lines="inset" @click="viewDashboard()" class="cursor-pointer">
      View Patient Overview
    </ion-item>
    <ion-item lines="none" @click="viewTasks()" class="cursor-pointer">
      View Patient Tasks
    </ion-item>
  </ion-list>
</template>

<script>
export default {
  name: "PopoverPatientOptions",
  methods: {
    viewDashboard() {
      this.$ionic.popoverController.dismiss();
    },
    viewTasks() {
      this.$ionic.popoverController.dismiss();
    }
  }
};
</script>
<style scoped>
ion-list.list-ios {
  margin-bottom: 0;
}
ion-item {
  padding: 0 5px;
}
ion-item:hover {
  background: var(--ion-color-light);
}
ion-label {
  font-weight: 500;
}
</style>
