<template>
  <form @submit.prevent="sendMessage" novalidate="true" class="pad-lr-ten margin-top-ten">
    <div class="prime-message-input-container" :class="{ errorPulse: errorPulse }">
      <ion-grid>
        <ion-row v-if="!activeConversation" class="ion-align-items-center bottom-divider-thin margin-bottom-ten">
          <div class="pad-left-ten text-muted text-small valign">
            Conversation Subject
            <span v-tooltip="'Will not be shown to the patient and is for your reference and documentation only.'">
              <IconInfoCircle class="form-tooltip-icon small margin-left-five" />
            </span>
          </div>
          <textarea-autosize :placeholder="copy.subjectPlaceholder" class="form-control prime-message-input" ref="outboundMessageInput" rows="1" v-model="outboundMessageSubject" :min-height="40" :max-height="350" @keydown.native.enter.exact.prevent @keyup.native.enter.exact="sendMessage" @keydown.native.enter.shift.exact="newline" />
        </ion-row>
        <ion-row class="ion-align-items-center">
          <ion-col>
            <textarea-autosize :placeholder="copy.placeholder" class="form-control prime-message-input" ref="outboundMessageInput" rows="1" v-model="outboundMessage" :min-height="40" :max-height="350" @keydown.native.enter.exact.prevent @keyup.native.enter.exact="sendMessage" @keydown.native.enter.shift.exact="newline" />
          </ion-col>
          <ion-col size="auto">
            <MessageInputTools
              v-bind="{
                msgChannel,
                beneficiaryId
              }"
            ></MessageInputTools>
          </ion-col>
          <ion-col size="auto">
            <button
              type="submit"
              class="prime-button send-message-button"
              :class="{
                'button-pending': isMessageSending,
                secure: msgChannel == 'zeus',
                disabled: disableSend
              }"
            >
              <span>
                <span v-if="isSecureChat">Send Securely</span>
                <ion-icon name="arrow-up" size="large" :color="'primary'" mode="md"></ion-icon>
              </span>
              <ion-spinner name="dots" duration="1200" color="white"></ion-spinner>
            </button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </form>
</template>
<script>
import { send as httpSend } from "@/services/Api";
import { EventBus } from "@/services/Events";
import { addIcons } from "ionicons";
import { arrowUp } from "ionicons/icons";
import MessageInputTools from "@/components/Chat/MessageInputTools";
import IconInfoCircle from "@/components/Global/Icons/IconInfoCircle";

addIcons({
  "md-arrow-up": arrowUp.md
});

export default {
  name: "MessageInput",
  components: {
    MessageInputTools,
    IconInfoCircle
  },
  data() {
    return {
      isMessageSending: false,
      outboundMessage: undefined,
      errorPulse: false,
      outboundMessageSubject: undefined,
      myInteractionId: this.interactionId
    };
  },
  props: {
    activeConversation: Boolean,
    interactionId: Number,
    beneficiaryId: Number,
    accountId: Number,
    copy: Object,
    msgChannel: String
  },
  computed: {
    isSecureChat() {
      return this.msgChannel === "zeus";
    },
    dbuggaMode() {
      return this.$store.getters["dbugga/dbuggaMode"];
    },
    disableSend() {
      return !this.outboundMessage || this.outboundMessage == "" || (!this.activeConversation && (!this.outboundMessageSubject || !this.outboundMessage));
    }
  },
  methods: {
    sendMessage: async function() {
      // dont do anything if no message is there to send.
      if (this.disableSend) {
        this.errorPulse = true;
        setTimeout(() => (this.errorPulse = false), 1000);
        return;
      }

      this.isMessageSending = true;
      
      if (!this.activeConversation) {
        this.myInteractionId = await this.initConvo(this);
      }

      const method = "POST";
      const path = document.config.messageUpdateApi[this.userType];
      const payload = await this.getNewMessagePayload(this.userType);

      try {
        await httpSend({
          path,
          method,
          payload,
          authToken: true,
          headers: {
            "Content-Type": "application/json;charset=utf-8"
          }
        });

        this.outboundMessage = "";
        this.isMessageSending = false;

        // raise event
      } catch (error) {
        khanSolo.log(error);
        this.outboundMessage = "";
        this.isMessageSending = false;
      } finally {
        EventBus.$emit(document.config.events.interaction, this.myInteractionId);
        this.$store.dispatch("chatProfessional/updateCurrentActiveConvos");
        this.$store.dispatch("chatProfessional/updateMessages", {id: this.interactionId, pageNumber: 1, messagesPerPage: 1 });
      }
    },
    initConvo: async function(that) {
      return new Promise(async function(resolve, reject) {
        const method = "post";
        const claims = await that.$authState.claims();
        const payload = {
          beneficiary_id: that.beneficiaryId,
          initiator: "conversation",
          urgency: "medium",
          professional_id: claims.professionalId,
          status: "active",
          subject: that.outboundMessageSubject || "untitled message"
        };
        const path = document.config.messageApi;

        httpSend({ path, payload, method, authToken: true })
          .then(result => {
            resolve(result.data.id);
            EventBus.$emit("newInteraction", result.data.id, that.msgChannel);
          })
          .catch(error => {
            khanSolo.log(error);
            reject(error);
          });
      });
    },
    newline() {
      this.value = `${this.value}\n`;
    },
    handleNewInput(input, willSend = false) {
      this.outboundMessage += input;
      if (willSend) {
        this.sendMessage();
        return;
      }
    },
    async getNewMessagePayload(role) {
      const claims = await this.$authState.claims();
      const payload = {
        professional: {
          interaction_id: this.myInteractionId,
          subject: null,
          body: this.outboundMessage,
          created_at: Math.floor(new Date().getTime()),
          professional_id: claims.professionalId || 0,
          account_id: this.accountId,
          external_correlation_id: 1,
          channel: this.msgChannel,
          urgency: "medium",
          direction: "outgoing",
          initiator: "conversation",
          status: "pending",
          source: "professional",
          provider: this.isDbugga && this.dbuggaMode ? "dbugga" : ""
        },
        patient: this.outboundMessage
      };

      return payload[role];
    }
  },
  created() {
    EventBus.$on("loadTemplateMessage", msgInput => {
      this.outboundMessage = msgInput;
    });
    EventBus.$on("addToMessageInput", this.handleNewInput);
  }
};
</script>

<style scoped>
.zeus-icon {
  padding-left: 10px;
  fill: white;
}
.prime-message-input-container {
  /* position:relative; */
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 1px 6px 0px rgba(46, 61, 73, 0.2);
  padding: 10px;
}
.form-control.prime-message-input {
  background: #ffffff;
  border: 2px solid #ffffff;
  line-height: 1.4;
  margin-bottom: 0;
  padding: 10px 5px 10px 5px;
  appearance: none;
}
.prime-message-input-container .send-message-button {
  width: 65px;
  /* display: block; */
  /* position: absolute; */
  /* right: 10px; */
  /* bottom: 10px; */
  padding-left: 0;
  padding-right: 0;
  transition: width 0s;
}

.prime-message-input-container .send-message-button.secure {
  font-size: 12px;
  width: 140px;
}
.prime-message-input-container .send-message-button ion-icon {
  margin-right: 0;
}
.prime-message-input-container .send-message-button ion-icon:only-child {
  margin-left: 0;
}
@keyframes border-pulsate {
  0% {
    background-color: rgba(0, 255, 255, 0);
  }
  50% {
    background-color: rgba(128, 255, 0, 0.5);
  }
  100% {
    background-color: rgba(0, 255, 255, 0);
  }
}
.errorPulse {
  animation: border-pulsate 1s 1;
}
</style>
