<template>
  <div>
    <EmojisTool v-if="isProfessional" />
    <VideoConferencingTool v-if="isProfessional && $can(I.CAN_INITIATE_VIRTUAL_VISIT)" />
    <PatientEducationTool
      v-if="isProfessional && ($can(I.VIEW_SUGGESTED_PRODUCTS) || $can(I.VIEW_HEALTH_EDUCATION))"
      :beneficiaryId="beneficiaryId"
    />
    <DbuggaTool v-if="msgChannel != 'zeus' && isDbugga" />
  </div>
</template>

<script>
import EmojisTool from "@/components/Tools/EmojisTool";
import PatientEducationTool from "@/components/Tools/PatientEducationTool";
import VideoConferencingTool from "@/components/Tools/VideoConferencingTool";
import DbuggaTool from "@/components/Tools/DbuggaTool";

export default {
  name: "MessageInputTools",
  components: {
    EmojisTool,
    PatientEducationTool,
    VideoConferencingTool,
    DbuggaTool
  },
  props: {
    msgChannel: String,
    beneficiaryId: Number
  },
  computed: {
    dbuggaMode() {
      return this.$store.getters["dbugga/dbuggaMode"];
    }
  }
};
</script>

<style>
.message-input-tool {
  fill: var(--ion-color-medium);
  font-size: 24px;
  margin-right: 5px;
  margin-top: 3px;
  transition: fill 0.2s ease-in-out;
}
.message-input-tool:hover {
  fill: var(--ion-color-dark-tint);
}
</style>
