<template>
  <IconVideo
    @click.native="showVideoConferencingModal"
    class="message-input-tool icon-small icon-grey"
  />
</template>

<script>
import IconVideo from "@/components/Global/Icons/IconVideo";
import ModalVideoConferencing from "@/components/VideoConferencing/ModalVideoConferencing";
export default {
  name: "VideoConferencingTool",
  components: {
    IconVideo
  },
  methods: {
    async showVideoConferencingModal() {
      const modalProperties = {
        mode: "ios",
        component: ModalVideoConferencing
      };
      const modal = await this.$ionic.modalController.create(modalProperties);
      return modal.present();
    }
  }
};
</script>

<style scoped>
svg {
  cursor: pointer;
}
</style>
