<template>
  <div id="__all-text">
    <div id="__transcript-text">
      ---------- LumerisEngage Automated Conversation Transcript ----------
      <div v-for="msg in chat" :key="msg.id">
        <span>{{ msg.getDisplayName() }} - </span>
        <span>{{ msg.getTimestamp() }}</span>
        <span v-if="msg.getChannel()"> - via {{ msg.getChannel() }}</span>
        <div>"{{ msg.body }}"</div>
        --
      </div>
    </div>
    <br />
    <br />
    <div>
      ----------
      <br />
      Task Comments
      <br />
      ----------
      <br />
      <div v-for="comment in commentary" :key="comment.id">
        <span>{{ comment.getDisplayName() }} - </span>
        <span>{{ comment.getTimestamp() }}</span>
        <span v-if="comment.getChannel()"> - via {{ comment.getChannel() }}</span>
        <div>"{{ comment.body }}"</div>
        --
      </div>
    </div>
  </div>
</template>

<script>
import utils from "@/utils/messageUtils";
import { EventBus } from "@/services/Events";

export default {
  name: "MessagesCopyToClipboard",
  props: {
    messages: Array,
    comments: Array
  },
  created() {
    EventBus.$on("copyMessagesToClipboard", () => {
      this.copy(false);
    });
    //TODO: Extend this component in the future to support comments
    // possibly send the messages with the eventBus instead of passing in with props might be better
    // EventBus.$on("copyMessagesToClipboardWithComments", () => {
    //   this.copy(false);
    // });
  },
  computed: {
    chat() {
      return this.convert(this.messages);
    },
    commentary() {
      return this.convert(this.comments);
    }
  },
  methods: {
    convert(messages) {
      if (!messages || messages.length == 0) {
        return "";
      }

      return messages.map(msg => utils.wrap(msg));
    },
    copy(withComments) {
      window.getSelection().removeAllRanges(); // clear current selection

      const id = withComments ? "__all-text" : "__transcript-text";

      const transcriptRange = document.createRange();
      transcriptRange.selectNodeContents(document.getElementById(id));
      window.getSelection().addRange(transcriptRange); // to select text

      document.execCommand("copy");
      window.getSelection().removeAllRanges(); // to deselect

      this.$ionic.toastController
        .create({
          header: "Copied!",
          message: "The chat transcript has been copied to your clipboard",
          duration: 4000,
          color: "primary",
          mode: "ios"
        })
        .then(m => m.present());
    }
  }
};
</script>

<style scoped>
#__all-text {
  position: fixed;
  width: 1px;
  height: 1px;
  left: -9999px;
}
</style>
