<template>
  <form @submit.prevent="save" novalidate="true" class="pad-lr-ten">
    <div class="prime-message-input-container" :class="{ errorPulse }">
      <ion-grid>
        <ion-row class="ion-align-items-center">
          <ion-col>
            <textarea-autosize :placeholder="placeholder" class="form-control prime-message-input" ref="textAreaInput" rows="1" v-model="data" :min-height="40" :max-height="350" @keydown.native.enter.exact.prevent @keyup.native.enter.exact="save" />
          </ion-col>
          <ion-col size="auto">
            <button
              type="submit"
              class="prime-button send-message-button"
              :class="{
                'button-pending': isSaving,
                'button-secondary': color === 'secondary'
              }"
            >
              <span>
                <ion-icon name="arrow-up" size="large" color="primary"></ion-icon>
              </span>
              <ion-spinner name="dots" duration="1200" color="white"></ion-spinner>
            </button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </form>
</template>
<script>
import { addIcons } from "ionicons";
import { arrowUp } from "ionicons/icons";

addIcons({
  "md-arrow-up": arrowUp.md
});

export default {
  name: "TextAreaInput",
  data() {
    return {
      data: this.value,
      errorPulse: false
    };
  },
  props: {
    color: String,
    value: String,
    placeholder: String,
    isSaving: Boolean
  },
  watch: {
    value(newValue) {
      this.data = newValue;
    }
  },
  methods: {
    save() {
      this.$emit("save", this.data);
      document.querySelector(".form-control.prime-message-input").value = "";
      this.data = "";
    }
  }
};
</script>

<style scoped>
.prime-message-input-container {
  padding: 10px;
}

.form-control.prime-message-input {
  background: #ffffff;
  border: 2px solid #ffffff;
  line-height: 1.4;
  margin-bottom: 0;
  padding: 10px 5px 10px 5px;
  appearance: none;
}

.form-control.prime-message-input::placeholder {
  color: var(--ion-color-medium);
}

.prime-message-input-container .send-message-button {
  width: 65px;
  padding-left: 0;
  padding-right: 0;
  transition: width 0s;
}

.prime-message-input-container .send-message-button ion-icon {
  margin-right: 0;
}

.prime-message-input-container .send-message-button ion-icon:only-child {
  margin-left: 0;
}

@keyframes border-pulsate {
  0% {
    background-color: rgba(0, 255, 255, 0);
  }
  50% {
    background-color: rgba(128, 255, 0, 0.5);
  }
  100% {
    background-color: rgba(0, 255, 255, 0);
  }
}
.errorPulse {
  animation: border-pulsate 1s 1;
}
</style>
